<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="home-page">
      <div class="row match-height">
        <div class="col-lg-12 col-12">
          <div class="card card-statistics">
            <div class="card-header">
              <h4 class="card-title">Statistics</h4>
              <div class="d-flex align-items-center">
                <p class="card-text mr-25 mb-0">Updated 1 month ago</p>
              </div>
            </div>
            <div class="card-body statistics-body">
              <div class="row d-flex align-items-center">
                <div class="col-md-3 col-sm-6 col-12 mb-2 mb-md-0">
                  <div class="media">
                    <div class="avatar bg-light-primary mr-2">
                      <div class="avatar-content">
                        <i data-feather="trending-up" class="avatar-icon"></i>
                      </div>
                    </div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0">Sales</h4>
                      <p class="card-text font-small-3 mb-0">{{ saleCount }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12 mb-2 mb-md-0">
                  <div class="media">
                    <div class="avatar bg-light-info mr-2">
                      <div class="avatar-content">
                        <i data-feather="user" class="avatar-icon"></i>
                      </div>
                    </div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0">Customers</h4>
                      <p class="card-text font-small-3 mb-0">{{ customerCount }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12 mb-2 mb-sm-0">
                  <div class="media">
                    <div class="avatar bg-light-danger mr-2">
                      <div class="avatar-content">
                        <i data-feather="box" class="avatar-icon"></i>
                      </div>
                    </div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0">Amount Pending</h4>
                      <p class="card-text font-small-3 mb-0">{{ pendingAmount | currency : "₹" }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="media">
                    <div class="avatar bg-light-success mr-2">
                      <div class="avatar-content">
                        <i data-feather="dollar-sign" class="avatar-icon"></i>
                      </div>
                    </div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0">Revenue</h4>
                      <p class="card-text font-small-3 mb-0">{{ revenueCount | currency : "₹" }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Basic Alerts end -->
  </div>
</div>
