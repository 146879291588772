<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="order-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body pb-1">
              <div class="row">
                <div class="col-12 mb-50">
                  <button class="btn btn-primary ml-1" (click)="modalOpen(modalBasic)" rippleEffect><i data-feather="plus" class="mr-0 mr-sm-1"></i><span class="d-none d-sm-inline-block">Add New Record</span></button>
                </div>
                <div class="col-md-6 col-12">
                  <div class="d-flex justify-content-between align-items-center m-1">
                    <label class="d-flex align-items-center"
                      >Show
                      <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries</label
                    >
                  </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                  <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search" class="form-control ml-25" (keyup)="filterUpdate($event)" /></label>
                  </div>
                </div>
              </div>
              <ngx-datatable [rows]="kitchenSinkRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption">
                <ngx-datatable-column name="Name" prop="customerId" [width]="280">
                  <ng-template let-row="row" let-name="value.name" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div class="cell-line-height">
                        <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                          {{ name }}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Amount" prop="amount" [width]="120">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div class="cell-line-height">
                        <p class="font-medium-1 font-weight-bold line-height-1 mb-25">{{ name | currency : "₹" }}</p>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Order Status" prop="orderStatus" [width]="130">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div class="cell-line-height">
                        <p class="font-medium-1 font-weight-bold line-height-1 mb-25" [ngClass]="getColor(name, 'orderStatus')">
                          {{ name }}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Payment Status" prop="paymentStatus" [width]="120">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div class="cell-line-height">
                        <p class="font-medium-1 font-weight-bold line-height-1 mb-25" [ngClass]="getColor(name, 'paymentStatus')">
                          {{ name }}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Payment Mode" prop="paymentType" [width]="120">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div class="cell-line-height">
                        <p class="font-medium-1 font-weight-bold line-height-1 mb-25" [ngClass]="getColor(name, 'paymentType')">
                          {{ name }}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div ngbDropdown container="body">
                        <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                          <a href="javascript:void(0)" (click)="measurementModalOpen(measurementDetail, row)" ngbDropdownItem class="d-flex align-items-center"><i data-feather="file-text" class="mr-50"></i> Details</a>
                          <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center" (click)="changeStatusOfOrder(row.orderId)" *ngIf="row.orderStatus != 'completed'"><i data-feather="zap" class="mr-50"></i> Change Order Status</a>
                          <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center" (click)="paymentModalOpen(paymentMode, row.orderId)"><i data-feather="credit-card" class="mr-50"></i> Change Payment Status</a>
                        </div>
                      </div>

                      <i data-feather="edit" class="text-primary cursor-pointer"></i>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Basic Alerts end -->
  </div>
</div>

<!-- add Modal -->
<ng-template #modalBasic let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Basic Modal</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <!-- <div class=""> -->
    <div id="stepper1" class="bs-stepper" [ngClass]="setStepperColor()">
      <div class="bs-stepper-header">
        <div class="step" data-target="#customer-form">
          <button class="step-trigger">
            <span class="bs-stepper-box">1</span>
            <span class="bs-stepper-label">
              <span class="bs-stepper-title">Customer Details</span>
              <span class="bs-stepper-subtitle">Setup Customer Details</span>
            </span>
          </button>
        </div>
        <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
        <div class="step" data-target="#dress-form">
          <button class="step-trigger">
            <span class="bs-stepper-box">2</span>
            <span class="bs-stepper-label">
              <span class="bs-stepper-title">Add Measurement</span>
              <span class="bs-stepper-subtitle">Add Measurement Info</span>
            </span>
          </button>
        </div>
        <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
        <div class="step" data-target="#payment-form">
          <button class="step-trigger">
            <span class="bs-stepper-box">3</span>
            <span class="bs-stepper-label">
              <span class="bs-stepper-title">Payment</span>
              <span class="bs-stepper-subtitle">Add Payment</span>
            </span>
          </button>
        </div>
      </div>
      <div class="bs-stepper-content">
        <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
          <!-- customer form -->
          <div id="customer-form" class="content">
            <form [formGroup]="accountDetailsForm">
              <div class="row">
                <div class="content-header col-md-6">
                  <h5 class="mb-0">Customer Details</h5>
                  <small class="text-muted">Enter Customer Details.</small>
                </div>
                <div class="content-header col-md-6 d-flex flex-md-row-reverse">
                  <button type="button" (click)="customerListModalOpen(modalSLC)" class="btn btn-outline-primary tabs" [ngClass]="setStepperColor()" rippleEffect>Select Customer</button>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-6">
                  <label class="form-label" for="name">Name</label>
                  <input formControlName="name" id="name" class="form-control" [ngClass]="{ 'is-invalid': accountDetailsControls.name.errors && accountDetailsFormSubmit }" placeholder="johndoe" />
                  <span *ngIf="accountDetailsControls.name.errors && accountDetailsFormSubmit" class="invalid-form">
                    <small class="form-text text-danger" *ngIf="accountDetailsControls.name.errors.required">This field is required!</small>
                  </span>
                </div>
                <div class="form-group col-md-6">
                  <label class="form-label" for="mail">Email</label>
                  <input formControlName="mail" id="mail" class="form-control" [ngClass]="{ 'is-invalid': accountDetailsControls.mail.errors && accountDetailsFormSubmit }" placeholder="john.doe@email.com" aria-label="john.doe" />
                  <span *ngIf="accountDetailsControls.mail.errors && accountDetailsFormSubmit" class="invalid-form">
                    <small class="form-text text-danger" *ngIf="accountDetailsControls.mail.errors.required">Email must be valid!</small>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="form-group form-password-toggle col-md-6">
                  <label class="form-label" for="contact">Contact</label>
                  <input formControlName="contact" id="contact" class="form-control" [ngClass]="{ 'is-invalid': accountDetailsControls.contact.errors && accountDetailsFormSubmit }" placeholder="9999988888" />
                  <div *ngIf="accountDetailsControls.contact.errors && accountDetailsFormSubmit" class="form-text text-danger">
                    <small *ngIf="accountDetailsControls.contact.errors.required">Contact is required.</small>
                  </div>
                </div>
                <div class="form-group form-password-toggle col-md-6">
                  <label class="form-label" for="altContact">Alternate Contact</label>
                  <input formControlName="altContact" id="altContact" class="form-control" placeholder="9999988888" />
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <button (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-secondary btn-prev" disabled rippleEffect>
                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                </button>
                <button (click)="horizontalWizardStepperNext(accountDetailsForm, 'account')" type="submit" class="btn btn-primary btn-next" rippleEffect>
                  <span class="align-middle d-sm-inline-block d-none">Next</span>
                  <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                </button>
              </div>
            </form>
          </div>
          <!-- customer form -->

          <!-- measurement form -->
          <div id="dress-form" class="content">
            <form [formGroup]="orderForm">
              <div class="content-header col-md-6">
                <h5 class="mb-0">Order Details</h5>
                <small class="text-muted">Enter Order Details.</small>
              </div>
              <div formArrayName="dressCollection">
                <div *ngFor="let dressDetail of dressCollectionControl; let ind = index" @heightIn>
                  <ng-container [formGroupName]="ind">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group mb-2"></div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-2">
                          <label for="configIdList">Dress</label>
                          <ng-select [items]="dressList" formControlName="dressId" bindLabel="name" (change)="onDressChange($event, ind)" bindValue="dressId">
                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label"> {{ item.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>
                          </ng-select>
                          <div *ngIf="measurementFormSubmit && getIndividualDressController(ind).dressId.errors" class="form-text text-danger">
                            <small *ngIf="getIndividualDressController(ind).dressId.errors.required">Please Choose a Dress</small>
                          </div>
                        </div>
                        <div class="form-group mb-2" *ngIf="customerMeasurementList[ind] && customerMeasurementList[ind][0]">
                          <label for="configIdList">Measurement List</label>
                          <ng-select [items]="customerMeasurementList[ind]" (change)="patchMeasurementDetails($event, ind)" bindLabel="tag">
                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label"> {{ item.tag }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>
                          </ng-select>
                        </div>
                        <div formArrayName="configList" class="row">
                          <div *ngFor="let configDetail of getConfigControls(ind); let i = index" class="dress-detail">
                            <ng-container [formGroupName]="i">
                              <div class="form-group col-10">
                                <label class="form-label">{{ configDetail.value.name }}</label>
                                <input formControlName="value" type="text" class="form-control" [readOnly]="isDisable[ind]" [ngClass]="{ 'is-invalid': measurementFormSubmit && configDetail.controls.value.errors }" />
                                <div *ngIf="measurementFormSubmit && configDetail.controls.value.errors" class="form-text text-danger">
                                  <small *ngIf="configDetail.controls.value.errors.required">{{ configDetail.value.name }} is Required</small>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-md-4">
                            <label class="form-label" for="tag">description</label>
                            <input formControlName="description" id="description" class="form-control" />
                          </div>
                          <div class="form-group col-md-4">
                            <label class="form-label" for="tag">Tag</label>
                            <input formControlName="tag" id="tag" class="form-control" [ngClass]="{ 'is-invalid': getIndividualDressController(ind).tag.errors && measurementFormSubmit }" />
                            <div *ngIf="getIndividualDressController(ind).tag.errors && measurementFormSubmit" class="form-text text-danger">
                              <small *ngIf="getIndividualDressController(ind).tag.errors.required">Tag is required.</small>
                            </div>
                          </div>
                          <div class="d-flex align-items-end col-md-2 col-12 mb-100" *ngIf="ind != 0">
                            <div class="form-group">
                              <button (click)="removeDressFromOrder(ind)" class="btn btn-outline-danger tabs text-nowrap px-1" [ngClass]="setStepperColor()" rippleEffect>
                                <i data-feather="trash-2" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Delete</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div formArrayName="materialList" class="row">
                          <div *ngFor="let materialDetail of getMaterialListController(ind); let i = index" class="material-detail">
                            <ng-container [formGroupName]="i">
                              <div class="form-group col-10">
                                <label class="form-label">Select material</label>
                                <ng-select [items]="serverMaterialList" id="materialsUpdate" (change)="materialChangeEvent($event, i, ind)" [hideSelected]="true" formControlName="name" bindLabel="name" bindValue="name">
                                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label"> {{ item.name }}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                  </ng-template>
                                </ng-select>
                                <label class="form-label">Length</label>
                                <input formControlName="length" type="text" class="form-control" [ngClass]="{ 'is-invalid': measurementFormSubmit && materialDetail.controls.length.errors }" />
                                <div *ngIf="measurementFormSubmit && materialDetail.controls.length.errors" class="form-text text-danger">
                                  <small *ngIf="materialDetail.controls.length.errors.required">{{ materialDetail.value.name }} is Required</small>
                                </div>
                              </div>
                            </ng-container>
                            <div class="d-flex align-items-end col-md-2 col-12 mb-100">
                              <div class="form-group">
                                <button (click)="removeMaterialDetail(i, ind)" class="btn btn-outline-danger tabs text-nowrap px-1" [ngClass]="setStepperColor()" rippleEffect>
                                  <i data-feather="trash-2" class="align-middle mr-sm-25 mr-0"></i>
                                  <span class="align-middle d-sm-inline-block d-none">Delete</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex align-items-center col-md-2 col-12 mb-100">
                            <button class="btn btn-primary" (click)="addMaterialDetail(ind)" rippleEffect>
                              <i data-feather="plus" class="align-middle mr-sm-25 mr-0"></i>
                              <span class="align-middle d-sm-inline-block d-none">Add Material</span>
                            </button>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </ng-container>
                  <hr />
                </div>
                <div class="d-flex flex-row-reverse mb-2">
                  <button (click)="addDressToOrder()" class="btn btn-outline-primary tabs" [ngClass]="setStepperColor()" rippleEffect>
                    <i data-feather="plus" class="align-middle mr-sm-25 mr-0"></i>
                    <span class="align-middle d-sm-inline-block d-none">Add Another Dress</span>
                  </button>
                </div>
                <div class="d-flex justify-content-between">
                  <button (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-secondary btn-prev" rippleEffect>
                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                  </button>
                  <button (click)="horizontalWizardStepperNext(orderForm, 'measurement')" type="submit" class="btn btn-primary btn-next" rippleEffect>
                    <span class="align-middle d-sm-inline-block d-none">Next</span>
                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- measurement form -->

          <!-- payment form -->
          <div id="payment-form" class="content">
            <div class="content-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Order Details</h5>
                <h5 class="mb-0 text-success">{{ getTotalAmount() | currency : "₹" }}</h5>
              </div>
              <!-- <div class="d-flex justify-content-center">
                <div class="order-card ml-4" *ngFor="let order of dressCollectionControl; let ind = index">
                  <div class="order-pricing-block-content">
                    <p class="order-pricing-plan">Measurement</p>
                    <div class="order-price-value">
                      <div id="order-priceDiscountCent">{{ order.value.dressName }}</div>
                    </div>
                    <ul class="order-check-list">
                      <li class="order-check-list-item" *ngFor="let config of getConfigControls(ind); let i = index">{{ config.value.name }} - {{ config.value.value }}</li>
                    </ul>
                  </div>
                </div>
              </div> -->
              <div class="row match-height">
                <div class="col-lg-4 d-flex align-items-stretch" *ngFor="let item of dressCollectionControl">
                  <div class="card">
                    <div class="card-body">
                      <h3>{{ item.value.dressName }}</h3>
                      <h6 class="text-muted">{{ item.value.description }}</h6>
                      <div class="badge badge-light-primary profile-badge">{{ item.value.tag }}</div>
                      <hr class="mb-2" />
                      <div class="card card-transaction">
                        <div class="card-header">
                          <h4 class="card-title">Measurement</h4>
                        </div>
                        <div class="card-body">
                          <div class="transaction-item" *ngFor="let config of item.value.configList">
                            <div class="media">
                              <div class="avatar bg-light-primary rounded">
                                <div class="avatar-content">
                                  <i data-feather="pocket" class="avatar-icon font-medium-3"></i>
                                </div>
                              </div>
                              <div class="media-body">
                                <h6 class="mt-1 transaction-title">{{ config.name }}</h6>
                              </div>
                            </div>
                            <div class="ml-2 font-weight-bolder text-success">{{ config.value }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="card card-transaction" *ngIf="item.value.materialList[0]">
                        <div class="card-header">
                          <h4 class="card-title" (click)="test(item)">Material</h4>
                        </div>
                        <div class="card-body">
                          <div class="transaction-item" *ngFor="let material of item.value.materialList">
                            <div class="media">
                              <div class="avatar bg-light-primary rounded">
                                <div class="avatar-content">
                                  <i data-feather="pocket" class="avatar-icon font-medium-3"></i>
                                </div>
                              </div>
                              <div class="media-body">
                                <h6 class="mt-1 transaction-title">{{ material.name }}</h6>
                              </div>
                            </div>
                            <div class="ml-2 font-weight-bolder text-success">{{ material.length }} m</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form [formGroup]="paymentForm">
              <div class="row">
                <div class="content-header col-md-6">
                  <h5 class="mb-0">Payment Details</h5>
                  <small class="text-muted">Enter Payment Details.</small>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12 col-sm-12 col-md-4">
                  <label class="form-label" for="amountPaid">Amount Paid</label>
                  <input type="number" formControlName="amountPaid" id="amountPaid" class="form-control" />
                </div>
                <div class="form-group col-12 ml-0 col-sm-12 col-md-4 container">
                  <p>Payment Status</p>
                  <div class="tabs mt-n1 form-check form-check-inline" [ngClass]="setStepperColor()">
                    <input formControlName="paymentStatus" class="form-check-input form-control" value="Paid" type="radio" id="radio-1" checked />
                    <label class="tab form-check-label" for="radio-1">Paid</label>
                    <input formControlName="paymentStatus" class="form-check-input form-control" value="pending" type="radio" id="radio-2" />
                    <label class="tab form-check-label" for="radio-2">Unpaid</label>
                    <span class="glider"></span>
                  </div>
                  <!-- <label class="form-label" for="paymentStatus">Payment Status</label>
                    <input formControlName="paymentStatus" id="paymentStatus" class="form-control" [ngClass]="{ 'is-invalid': paymentControls.paymentStatus.errors && paymentFormSubmit }" />
                    <span *ngIf="paymentControls.paymentStatus.errors && paymentFormSubmit" class="invalid-form">
                      <small class="form-text text-danger" *ngIf="paymentControls.paymentStatus.errors.required">This field is required!</small>
                    </span> -->
                </div>
                <div class="form-group col-12 ml-0 col-sm-12 col-md-4 container" *ngIf="paymentControls.paymentStatus.value === 'Paid'">
                  <p>Payment Mode</p>
                  <div class="tabs mt-n1 form-check form-check-inline" [ngClass]="setStepperColor()">
                    <input formControlName="paymentType" class="form-check-input form-control" value="cash" type="radio" id="radio-3" checked />
                    <label class="tab form-check-label" for="radio-3">Cash</label>
                    <input formControlName="paymentType" class="form-check-input form-control" value="online" type="radio" id="radio-4" />
                    <label class="tab form-check-label" for="radio-4">Online</label>
                    <span class="glider"></span>
                  </div>
                  <!-- <label class="form-label" for="paymentType">Payment Mode</label>
                    <input formControlName="paymentType" id="paymentType" class="form-control" [ngClass]="{ 'is-invalid': paymentControls.paymentType.errors && paymentFormSubmit }" aria-label="john.doe" />
                    <span *ngIf="paymentControls.paymentType.errors && paymentFormSubmit" class="invalid-form">
                      <small class="form-text text-danger" *ngIf="paymentControls.paymentType.errors.required">Email must be valid!</small>
                    </span> -->
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <button (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-secondary btn-prev" rippleEffect>
                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                </button>
                <button (click)="submitOrderForm()" type="submit" class="btn btn-success btn-submit" rippleEffect>
                  <span class="align-middle d-sm-inline-block d-none">Submit</span>
                  <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                </button>
              </div>
            </form>
          </div>
          <!-- payment form -->
        </form>
      </div>
    </div>
    <!-- </div> -->
  </div>
</ng-template>
<!-- / Modal -->

<!-- Button trigger modal -->

<!-- customer list Modal -->
<ng-template #modalSLC let-modal>
  <div class="scrolling-long-content">
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel1">Customer List</h4>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="row match-height">
        <!-- Customer list card card -->
        <div class="input-group input-group-merge mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon-search2"><i data-feather="search"></i></span>
          </div>
          <input type="text" class="form-control" placeholder="Search..." aria-label="Search..." aria-describedby="basic-addon-search2" (keyup)="filterCustomer($event)" />
        </div>
        <div class="col-lg-4 d-flex align-items-stretch" *ngFor="let customer of customerTempList">
          <div class="card">
            <div class="card-header">
              <!-- <h4 class="card-title">Customer List</h4> -->
              <div class="media">
                <div class="avatar bg-light-primary rounded">
                  <div class="avatar-content">
                    <i data-feather="users" class="avatar-icon font-medium-3"></i>
                  </div>
                </div>
                <div class="media-body">
                  <h6 class="transaction-title mt-1">{{ customer.name }}</h6>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="transaction-item">
                <div class="font-weight-bolder mb-2">
                  <h6 class="">{{ customer.contact }}</h6>
                  <small>{{ customer.mail }}</small>
                </div>
                <button type="button" class="btn btn-primary" (click)="selectCustomer(customer); modal.dismiss('Cross click')" rippleEffect>Select</button>
              </div>
            </div>
          </div>
        </div>

        <!--/ Customer list card card -->
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->

<!-- detail Modal -->
<ng-template #measurementDetail let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Order Detail</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <div class="row match-height">
          <!-- measurement Card -->
          <div class="col-lg-4 d-flex align-items-stretch" *ngFor="let item of orderTempDetails.dressList">
            <div class="card card-profile">
              <div class="card-body">
                <h3>{{ item.measurementId.dressId.name }}</h3>
                <h6 class="text-muted">{{ item.measurementId.description }}</h6>
                <div class="badge badge-light-primary profile-badge">{{ item.measurementId.dressId.price | currency : "₹" }}</div>
                <hr class="mb-2" />
                <div class="card card-transaction">
                  <div class="card-header">
                    <h4 class="card-title">Measurement</h4>
                  </div>
                  <div class="card-body">
                    <div class="transaction-item" *ngFor="let config of item.measurementId.configList">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <i data-feather="pocket" class="avatar-icon font-medium-3"></i>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="mt-1 transaction-title">{{ config.configId.name }}</h6>
                          <!-- <small>{{ config }}</small> -->
                        </div>
                      </div>
                      <div class="ml-2 font-weight-bolder text-success">{{ config.value + " " + config.configId.unit }}</div>
                    </div>
                  </div>
                </div>
                <div class="card card-transaction" *ngIf="item.materialList[0]">
                  <div class="card-header">
                    <h4 class="card-title">Material</h4>
                  </div>
                  <div class="card-body">
                    <div class="transaction-item" *ngFor="let material of item.materialList">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <i data-feather="pocket" class="avatar-icon font-medium-3"></i>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="mt-1 transaction-title">{{ material.materialId.name }}</h6>
                          <!-- <small>{{ config }}</small> -->
                        </div>
                      </div>
                      <div class="ml-2 font-weight-bolder text-success">{{ material.length}} m</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /measurement card -->
        </div>
        <hr />
        <div class="row match-height">
          <div class="col-lg-6 col-md-6 mb-2">
            <label for="amount">Total Amount</label>
            <div class="d-flex justify-content-center align-items-center badge badge-pill badge-light-dark">
              <div>
                <h6 class="font-weight-bolder">{{ orderTempDetails.amount }}</h6>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 mb-2">
            <label for="amount">Amount Paid</label>
            <div class="d-flex justify-content-center align-items-center badge badge-pill badge-light-dark">
              <div>
                <h6 class="font-weight-bolder">{{ orderTempDetails.amountPaid }}</h6>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 mb-2">
            <label for="amount">Amount Remaining</label>
            <div class="d-flex justify-content-center align-items-center badge badge-pill badge-light-dark">
              <div>
                <h6 class="font-weight-bolder">{{ orderTempDetails.amountRemaining }}</h6>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 mb-2">
            <label for="orderStatus">Order Status</label>
            <div class="d-flex justify-content-center align-items-center badge badge-pill" [ngClass]="getColorInDetails('orderStatus', orderTempDetails.orderStatus)" id="orderStatus">
              <div>
                <h6 class="font-weight-bolder" (click)="changeOrderStatus()">{{ orderTempDetails.orderStatus }}</h6>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 mb-2">
            <label for="paymentStatus">Payment Status</label>
            <div class="d-flex justify-content-center align-items-center badge badge-pill" [ngClass]="getColorInDetails('paymentStatus', orderTempDetails.paymentStatus)" id="paymentStatus">
              <div>
                <h6 class="font-weight-bolder">{{ orderTempDetails.paymentStatus }}</h6>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 mb-2">
            <label for="paymentType">Payment Mode</label>
            <div class="d-flex justify-content-center align-items-center badge badge-pill" [ngClass]="getColorInDetails('paymentType', orderTempDetails.paymentType)" id="paymentType">
              <div>
                <h6 class="font-weight-bolder">{{ orderTempDetails.paymentType }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->

<!-- change payment mode Modal -->
<ng-template #paymentMode let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex justify-content-center" id="myModalLabel1">Please select Payment Mode</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="content-wrapper container-xxl p-0 d-flex justify-content-between">
      <!-- <div class="content-body">
        <div class="d-flex justify-content-center align-items-center text-bold">
          Please select Payment Mode
        </div>
      </div> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="changeStatusOfPayment('cash'); modal.close('Accept click')">Cash</button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="changeStatusOfPayment('online'); modal.close('Accept click')">Online</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->
