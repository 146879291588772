<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="home-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body pb-1">
              <div class="row">
                <div class="col-12 mb-50">
                  <button class="btn btn-primary ml-1" (click)="this.addCustomerForm.reset(); modalOpen(addCustomer, 'l')" rippleEffect><i data-feather="plus" class="mr-0 mr-sm-1"></i><span class="d-none d-sm-inline-block">Add New Record</span></button>
                </div>
                <div class="col-md-6 col-12">
                  <div class="d-flex justify-content-between align-items-center m-1">
                    <label class="d-flex align-items-center"
                      >Show
                      <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries</label
                    >
                  </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                  <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search" class="form-control ml-25" (keyup)="filterUpdate($event)" /></label>
                  </div>
                </div>
              </div>
              <ngx-datatable [rows]="kitchenSinkRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption">
                <ngx-datatable-column name="Name" prop="name" [width]="280">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div class="cell-line-height">
                        <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                          {{ name }}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Contact" prop="contact" [width]="250"></ngx-datatable-column>
                <ngx-datatable-column name="Alternate Contact" prop="altContact" [width]="120"></ngx-datatable-column>
                <ngx-datatable-column name="Email" prop="mail" [width]="220"> </ngx-datatable-column>
                <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div ngbDropdown container="body">
                        <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                          <a href="javascript:void(0)" (click)="setCustomer(row, true); modalOpen(measurementDetail, 'xl')" ngbDropdownItem class="d-flex align-items-center"><i data-feather="file-text" class="mr-50"></i> Measurement Details</a>
                          <a href="javascript:void(0)" (click)="setCustomer(row, false); modalOpen(updateCustomer, 'l')" ngbDropdownItem class="d-flex align-items-center"><i data-feather="archive" class="mr-50"></i> Edit</a>
                          <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center" (click)="removeCustomer(row.customerId)"><i data-feather="trash-2" class="mr-50"></i> Delete</a>
                        </div>
                      </div>

                      <i data-feather="edit" class="text-primary cursor-pointer"></i>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Basic Alerts end -->
  </div>
</div>

<!-- add Modal -->
<ng-template #addCustomer let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Add Customer</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div id="account-details">
      <form [formGroup]="addCustomerForm" (ngSubmit)="submitAddCustomerForm(true)">
        <!-- <div class="row">
          <div class="content-header col-md-6">
            <h5 class="mb-0">Customer Details</h5>
            <small class="text-muted">Enter Customer Details.</small>
          </div>
        </div> -->

        <div class="row">
          <div class="form-group col-md-6">
            <label class="form-label" for="name">Name</label>
            <input formControlName="name" id="name" class="form-control" [ngClass]="{ 'is-invalid': addCustomerFormControls.name.errors && addCustomerFormSubmit }" placeholder="johndoe" />
            <span *ngIf="addCustomerFormControls.name.errors && addCustomerFormSubmit" class="invalid-form">
              <small class="form-text text-danger" *ngIf="addCustomerFormControls.name.errors.required">This field is required!</small>
            </span>
          </div>
          <div class="form-group col-md-6">
            <label class="form-label" for="mail">Email</label>
            <input formControlName="mail" id="mail" class="form-control" [ngClass]="{ 'is-invalid': addCustomerFormControls.mail.errors && addCustomerFormSubmit }" placeholder="john.doe@email.com" aria-label="john.doe" />
            <span *ngIf="addCustomerFormControls.mail.errors && addCustomerFormSubmit" class="invalid-form">
              <small class="form-text text-danger" *ngIf="addCustomerFormControls.mail.errors.required">Email must be valid!</small>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="form-group form-password-toggle col-md-6">
            <label class="form-label" for="contact">Contact</label>
            <input formControlName="contact" id="contact" class="form-control" [ngClass]="{ 'is-invalid': addCustomerFormControls.contact.errors && addCustomerFormSubmit }" placeholder="9999988888" />
            <div *ngIf="addCustomerFormControls.contact.errors && addCustomerFormSubmit" class="form-text text-danger">
              <small *ngIf="addCustomerFormControls.contact.errors.required">Contact is required.</small>
            </div>
          </div>
          <div class="form-group form-password-toggle col-md-6">
            <label class="form-label" for="altContact">Alternate Contact</label>
            <input formControlName="altContact" id="altContact" class="form-control" placeholder="9999988888" />
          </div>
        </div>
        <div class="d-flex flex-row-reverse">
          <button type="submit" class="btn btn-primary btn-next" rippleEffect>
            <span class="align-middle d-sm-inline-block">Add</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- / Modal -->

<!-- add Modal -->
<ng-template #updateCustomer let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Update Customer</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div id="account-details">
      <form [formGroup]="addCustomerForm" (ngSubmit)="submitAddCustomerForm(false)">
        <div class="row">
          <div class="form-group col-md-6">
            <label class="form-label" for="name">Name</label>
            <input formControlName="name" id="name" class="form-control" [ngClass]="{ 'is-invalid': addCustomerFormControls.name.errors && addCustomerFormSubmit }" placeholder="johndoe" />
            <span *ngIf="addCustomerFormControls.name.errors && addCustomerFormSubmit" class="invalid-form">
              <small class="form-text text-danger" *ngIf="addCustomerFormControls.name.errors.required">This field is required!</small>
            </span>
          </div>
          <div class="form-group col-md-6">
            <label class="form-label" for="mail">Email</label>
            <input formControlName="mail" id="mail" class="form-control" [ngClass]="{ 'is-invalid': addCustomerFormControls.mail.errors && addCustomerFormSubmit }" placeholder="john.doe@email.com" aria-label="john.doe" />
            <span *ngIf="addCustomerFormControls.mail.errors && addCustomerFormSubmit" class="invalid-form">
              <small class="form-text text-danger" *ngIf="addCustomerFormControls.mail.errors.required">Email must be valid!</small>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="form-group form-password-toggle col-md-6">
            <label class="form-label" for="contact">Contact</label>
            <input formControlName="contact" id="contact" class="form-control" [ngClass]="{ 'is-invalid': addCustomerFormControls.contact.errors && addCustomerFormSubmit }" placeholder="9999988888" />
            <div *ngIf="addCustomerFormControls.contact.errors && addCustomerFormSubmit" class="form-text text-danger">
              <small *ngIf="addCustomerFormControls.contact.errors.required">Contact is required.</small>
            </div>
          </div>
          <div class="form-group form-password-toggle col-md-6">
            <label class="form-label" for="altContact">Alternate Contact</label>
            <input formControlName="altContact" id="altContact" class="form-control" placeholder="9999988888" />
          </div>
        </div>
        <div class="d-flex flex-row-reverse">
          <button type="submit" class="btn btn-primary btn-next" rippleEffect>
            <span class="align-middle d-sm-inline-block">Add</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- / Modal -->

<!-- detail Modal -->
<ng-template #measurementDetail let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Customer Detail</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <div class="row match-height">
          <!-- measurement Card -->
          <div class="col-lg-4 col-md-6 col-12" *ngFor="let item of measurementTempList">
            <div class="card card-profile">
              <div class="card-body">
                <!-- <div class="profile-image-wrapper">
                  <div class="profile-image">
                    <div class="avatar">
                      <img src="assets/images/portrait/small/avatar-s-9.jpg" alt="Profile Picture" />
                    </div>
                  </div>
                </div> -->
                <h3>{{ item.dressId.name }}</h3>
                <h6 class="text-muted">{{ item.description }}</h6>
                <div class="badge badge-light-primary profile-badge">{{ item.tag }}</div>
                <hr class="mb-2" />
                <div class="card card-transaction">
                  <div class="card-header">
                    <h4 class="card-title">Transactions</h4>
                  </div>
                  <div class="card-body">
                    <div class="transaction-item" *ngFor="let config of item.configList">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <i data-feather="pocket" class="avatar-icon font-medium-3"></i>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">{{ config.configId.name }}</h6>
                          <!-- <small>{{ config }}</small> -->
                        </div>
                      </div>
                      <div class="font-weight-bolder text-success">{{ config.value }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /measurement card -->
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->
