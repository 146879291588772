<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
  <li ngbNavItem>
    <a ngbNavLink>Dress</a>
    <ng-template ngbNavContent>
      <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
          <app-content-header [contentHeader]="contentHeader"></app-content-header>

          <!-- Card Advance -->
          <div class="row match-height">
            <!-- Employee Task Card -->
            <div class="col-lg-4 col-md-6 col-12" *ngFor="let dress of dressList">
              <div class="card card-employee-task">
                <div class="card-header">
                  <h4 class="card-title">
                    {{ dress.name }}<br /><small>{{ dress.price | currency : "₹" }}</small>
                  </h4>

                  <div ngbDropdown class="dropdown-icon-wrapper">
                    <a ngbDropdownToggle href="javascript:void(0);" id="dropdownBrowserState2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50 dropdown-icon"></i>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState2">
                      <a href="javascript:void(0)" ngbDropdownItem (click)="dressModalOpen(dressModal, dress)" class="d-flex align-items-center"><i data-feather="file-text" class="mr-50"></i> Edit</a>
                      <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center" (click)="removeDress(dress.dressId)"><i data-feather="trash-2" class="mr-50"></i> Delete</a>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="employee-task d-flex justify-content-between align-items-center" *ngFor="let config of dress.configIdList">
                    <div class="media">
                      <div class="avatar bg-light-primary rounded">
                        <div class="avatar-content">
                          <i data-feather="pocket" class="avatar-icon font-medium-3"></i>
                        </div>
                      </div>
                      <!-- <div class="avatar mr-75">
                        <img src="assets/images/icons/ruler.svg" class="rounded" width="42" height="42" alt="Avatar" />
                      </div> -->
                      <div class="media-body my-auto">
                        <h6 class="mb-0 ml-1">{{ config.name }}</h6>
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <small class="mr-75">{{ config.unit }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Employee Task Card -->
            <!-- Payment Card -->
            <div class="col-lg-4 col-md-6 col-12">
              <div class="card card-payment">
                <div class="card-header">
                  <h4 class="card-title">Add New Dress</h4>
                </div>
                <div class="card-body">
                  <form [formGroup]="dressForm" class="form form-vertical" (ngSubmit)="addDress()">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group mb-2">
                          <label for="payment-card-number">Dress Name</label>
                          <input type="text" id="payment-card-number" name="name" formControlName="name" class="form-control" placeholder="Kurti" [ngClass]="{ 'is-invalid': DSubmitted && DControls.name.errors }" />
                          <div *ngIf="DSubmitted && DControls.name.errors" class="text-danger">
                            <div *ngIf="DControls.name.errors.required">name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-2"></div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-2">
                          <label for="payment-card-number">Price</label>
                          <input type="number" id="payment-card-number" name="price" formControlName="price" class="form-control" placeholder="400" [ngClass]="{ 'is-invalid': DSubmitted && DControls.price.errors }" />
                          <div *ngIf="DSubmitted && DControls.name.errors" class="text-danger">
                            <div *ngIf="DControls.name.errors.required">Price is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-2"></div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-2">
                          <label for="configIdList">Measurement</label>
                          <ng-select [multiple]="true" [items]="measurementTempList" (change)="onConfigIdListChange($event)" formArrayName="configIdList" bindLabel="name" bindValue="configId" [hideSelected]="true" [ngClass]="{ error: DSubmitted && DControls.configIdList.errors }">
                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label"> {{ item.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>
                          </ng-select>
                          <div *ngIf="DSubmitted && DControls.configIdList.errors" class="text-danger">
                            <div *ngIf="DControls.configIdList.errors.required">Measurement is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-2"></div>
                      </div>
                      <div class="form-group col-12">
                        <button type="submit" class="btn btn-primary btn-block" rippleEffect>Add</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!--/ Payment Card -->
          </div>
          <!--/ Card Advance -->
        </div>
      </div>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Measurement</a>
    <ng-template let-row="row" ngbNavContent>
      <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
          <app-content-header [contentHeader]="contentHeader"></app-content-header>
          <div class="row match-height">
            <!-- Measurement List Card -->
            <div class="col-12">
              <div class="card card-employee-task">
                <div class="card-body">
                  <div class="employee-task justify-content-between align-items-center">
                    <ul class="list-group d-flex flex-wrap list-group-horizontal">
                        <li class="list-group-item" *ngFor="let measurementConfig of measurementTempList">
                          <div class="d-flex justify-content-between">
                            <span class="font-weight-bolder">
                              {{ measurementConfig.name }}</span
                            >
                            <spam class="pl-1"><small>{{ measurementConfig.unit }}</small></spam>

                          </div>
                          <div ngbDropdown class="dropdown d-flex align-items-end">
                            <a ngbDropdownToggle class="btn btn-outline-primary dropdown-toggle"href="javascript:void(0);"  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">more</a>

                            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                              <a href="javascript:void(0)" ngbDropdownItem (click)="modalOpen(measurementConfigModal, measurementConfig)" class="d-flex align-items-center"><i data-feather="file-text" class="mr-50"></i> Edit</a>
                              <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center" (click)="removeMeasurementConfig(measurementConfig.configId)"><i data-feather="trash-2" class="mr-50"></i> Delete</a>
                            </div>
                          </div>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Measurement List Card -->
            <!-- Measurement Add Card -->
            <div class="col-12">
              <div class="card card-payment">
                <div class="card-header">
                  <h4 class="card-title">Add Measurement</h4>
                </div>
                <div class="card-body">
                  <form [formGroup]="measurementConfigForm" class="form form-vertical" (ngSubmit)="addMeasurementConfig()">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group mb-2">
                          <label for="payment-card-number">Measurement Name</label>
                          <input type="text" name="name" formControlName="name" class="form-control" placeholder="Length" [ngClass]="{ 'is-invalid': mCSubmitted && mControls.name.errors }" />
                          <div *ngIf="mCSubmitted && mControls.name.errors" class="text-danger">
                            <div *ngIf="mControls.name.errors.required">name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                          <label for="payment-cvv">Unit</label>
                          <input type="text" name="unit" formControlName="unit" class="form-control disable" placeholder="Cm" [ngClass]="{ 'is-invalid': mCSubmitted && mControls.unit.errors }" />
                          <div *ngIf="mCSubmitted && mControls.unit.errors" class="text-danger">
                            <div *ngIf="mControls.unit.errors.required">unit is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                          <label for="payment-expiry" class="mb-1">Has Unit</label>
                          <label class="m-switch">
                            <input id="payment-expiry" name="isUnit" formControlName="isUnit" class="m-toggle form-control" type="checkbox" />
                            <span class="m-slider"></span>
                            <span class="m-card-side"></span>
                          </label>
                          <!-- <input type="number" id="payment-expiry" class="form-control" placeholder="" /> -->
                        </div>
                      </div>
                      <div class="form-group col-12">
                        <button type="submit" class="btn btn-primary btn-block" rippleEffect>Add Measurement</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!--/ Measurement Add Card -->
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Material</a>
    <ng-template let-row="row" ngbNavContent>
      <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
          <app-content-header [contentHeader]="contentHeader"></app-content-header>
          <div class="row match-height">
            <!-- Measurement List Card -->
            <div class="col-12">
              <div class="card card-employee-task">
                <div class="card-body">
                  <div class="employee-task justify-content-between align-items-center">
                    <ul class="list-group d-flex flex-wrap list-group-horizontal">
                        <li class="list-group-item" *ngFor="let material of materialTempList">
                          <div class="d-flex justify-content-between">
                            <span class="font-weight-bolder">
                              {{ material.name }}</span
                            >
                            <spam class="pl-1 font-weight-bolder">{{ material.price | currency:"₹" }}</spam>

                          </div>
                          <div ngbDropdown class="dropdown d-flex align-items-end">
                            <a ngbDropdownToggle class="btn btn-outline-primary dropdown-toggle"href="javascript:void(0);"  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">more</a>

                            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                              <a href="javascript:void(0)" ngbDropdownItem (click)="materialModalOpen(materialModal, material)" class="d-flex align-items-center"><i data-feather="file-text" class="mr-50"></i> Edit</a>
                              <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center" (click)="removeMaterial(material.materialId)"><i data-feather="trash-2" class="mr-50"></i> Delete</a>
                            </div>
                          </div>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Measurement List Card -->
            <!-- Measurement Add Card -->
            <div class="col-12">
              <div class="card card-payment">
                <div class="card-header">
                  <h4 class="card-title">Add Material</h4>
                </div>
                <div class="card-body">
                  <form [formGroup]="materialForm" class="form form-vertical" (ngSubmit)="addMaterial()">
                    <div class="row">
                      <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                          <label for="payment-card-number">Material Name</label>
                          <input type="text" name="name" formControlName="name" class="form-control" placeholder="Cotton" [ngClass]="{ 'is-invalid': materialSubmitted && materialControls.name.errors }" />
                          <div *ngIf="materialSubmitted && materialControls.name.errors" class="text-danger">
                            <div *ngIf="materialControls.name.errors.required">name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                          <label for="payment-cvv">Price per meter</label>
                          <input type="number" name="price" formControlName="price" class="form-control" placeholder="100" [ngClass]="{ 'is-invalid': materialSubmitted && materialControls.price.errors }" />
                          <div *ngIf="materialSubmitted && materialControls.price.errors" class="text-danger">
                            <div *ngIf="materialControls.price.errors.required">price is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-12">
                        <button type="submit" class="btn btn-primary btn-block" rippleEffect>Add Material</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!--/ Measurement Add Card -->
          </div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="navFilled" class="mt-2"></div>

<!-- measurement config modal -->
<ng-template #measurementConfigModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Basic Modal</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form [formGroup]="measurementConfigForm" class="form form-vertical" (ngSubmit)="updateMeasurementConfig()">
      <div class="row">
        <div class="col-12">
          <div class="form-group mb-2">
            <label for="payment-card-number">Measurement Name</label>
            <input type="text" name="name" formControlName="name" class="form-control" placeholder="Length" [ngClass]="{ 'is-invalid': mCSubmitted && mControls.name.errors }" />
            <div *ngIf="mCSubmitted && mControls.name.errors" class="text-danger">
              <div *ngIf="mControls.name.errors.required">name is required</div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <div class="form-group mb-2">
            <label for="payment-cvv">Unit</label>
            <input type="text" name="unit" formControlName="unit" class="form-control disable" placeholder="Cm" [ngClass]="{ 'is-invalid': mCSubmitted && mControls.unit.errors }" />
            <div *ngIf="mCSubmitted && mControls.unit.errors" class="text-danger">
              <div *ngIf="mControls.unit.errors.required">unit is required</div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <div class="form-group mb-2">
            <label for="payment-expiry" class="mb-1">Has Unit</label>
            <label class="m-switch">
              <input id="payment-expiry" name="isUnit" formControlName="isUnit" class="m-toggle form-control" type="checkbox" />
              <span class="m-slider"></span>
              <span class="m-card-side"></span>
            </label>
            <!-- <input type="number" id="payment-expiry" class="form-control" placeholder="" /> -->
          </div>
        </div>
        <div class="form-group col-12">
          <button type="submit" class="btn btn-primary btn-block" rippleEffect>Update Measurement</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!-- /measurement config modal -->

<!-- dress modal -->
<ng-template #dressModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Basic Modal</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form [formGroup]="dressForm" class="form form-vertical" (ngSubmit)="updateDress()">
      <div class="row">
        <div class="col-12">
          <div class="form-group mb-2">
            <label for="payment-card-number">Dress Name</label>
            <input type="text" id="payment-card-number" name="name" formControlName="name" class="form-control" placeholder="Kurti" [ngClass]="{ 'is-invalid': DSubmitted && DControls.name.errors }" />
            <div *ngIf="DSubmitted && DControls.name.errors" class="text-danger">
              <div *ngIf="DControls.name.errors.required">name is required</div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group mb-2"></div>
        </div>
        <div class="col-12">
          <div class="form-group mb-2">
            <label for="payment-card-number">Price</label>
            <input type="number" id="payment-card-number" name="price" formControlName="price" class="form-control" placeholder="400" [ngClass]="{ 'is-invalid': DSubmitted && DControls.price.errors }" />
            <div *ngIf="DSubmitted && DControls.name.errors" class="text-danger">
              <div *ngIf="DControls.name.errors.required">Price is required</div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group mb-2"></div>
        </div>
        <div class="col-12">
          <div class="form-group mb-2">
            <label for="configIdList">Measurement</label>
            <ng-select [multiple]="true" [items]="measurementTempList" id="dressUpdate" [hideSelected]="true" [(ngModel)]="nameAndIdList" [ngModelOptions]="{ standalone: true }" bindLabel="name" [ngClass]="{ error: DSubmitted && DControls.configIdList.errors }">
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-label"> {{ item.name }}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </ng-template>
            </ng-select>
            <div *ngIf="DSubmitted && DControls.configIdList.errors" class="text-danger">
              <div *ngIf="DControls.configIdList.errors.required">Measurement is required</div>
            </div>
          </div>
        </div>
        <div class="form-group col-12">
          <button type="submit" class="btn btn-primary btn-block" rippleEffect>Add</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!-- /dress modal -->

<!-- material modal -->
<ng-template #materialModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Update material</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form [formGroup]="materialForm" class="form form-vertical" (ngSubmit)="updateMaterial()">
      <div class="row">
        <div class="col-sm-6 col-12">
          <div class="form-group mb-2">
            <label for="payment-card-number">Material Name</label>
            <input type="text" name="name" formControlName="name" class="form-control" placeholder="Cotton" [ngClass]="{ 'is-invalid': materialSubmitted && materialControls.name.errors }" />
            <div *ngIf="materialSubmitted && materialControls.name.errors" class="text-danger">
              <div *ngIf="materialControls.name.errors.required">name is required</div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <div class="form-group mb-2">
            <label for="payment-cvv">Price per meter</label>
            <input type="number" name="price" formControlName="price" class="form-control" placeholder="Cm" [ngClass]="{ 'is-invalid': materialSubmitted && materialControls.price.errors }" />
            <div *ngIf="materialSubmitted && materialControls.price.errors" class="text-danger">
              <div *ngIf="materialControls.price.errors.required">Price is required</div>
            </div>
          </div>
        </div>
        <div class="form-group col-12">
          <button type="submit" class="btn btn-primary btn-block" rippleEffect>Update Material</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!-- /material modal -->
