export const locale = {
  lang: "en",
  data: {
    MENU: {
      HOME: "Home",
      CUSTOMER: "Customer",
      CONFIG: "Config",
      ORDER: "Order",
    },
  },
};
